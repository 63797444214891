import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Authentication from '../../../modules/Authentication';
import InnerWindow from '../../../components/InnerWindow';
import clsx from 'clsx';
import autoOnIcon from '../../../res/images/power_circle.svg';
import resetIcon from '../../../res/images/refresh_circle.svg';
import closeIcon from '../../../res/images/close_circle_black.svg';
import machineIcon from '../../../res/images/machine.svg';
import commandSentIcon from '../../../res/images/broadcast.svg';
import commandReceivedIcon from '../../../res/images/machine_broadcast.svg';
import commandCompleteIcon from '../../../res/images/machine_success.svg';
import commandFailedIcon from '../../../res/images/machine_error.svg';
import Button from '../../../components/Button';
import { IconButton } from '@material-ui/core';
import { hexColorToCSSFilter } from '../../../modules/CSSUtils';
import Radix64 from '../../../modules/Radix64';
import DateTime from 'luxon/src/datetime';

const COMMAND_TYPES = [
    {
        name: 'Auto On',
        command: '38',
        icon: autoOnIcon,
        question: 'Are you sure you want to turn %MACHINE% auto on?',
    },
    {
        name: 'Reset',
        command: '40',
        icon: resetIcon,
        question: 'Are you sure you want to reset %MACHINE%?',
    },
    {
        name: 'Activate Continuous Logs',
        command: '42',
        icon: machineIcon,
        question:
            'Are you sure you want to activate continuous logs for %MACHINE%?',
    },
    {
        name: 'Deactivate Continuous Logs',
        command: '43',
        icon: machineIcon,
        question:
            'Are you sure you want to deactivate continuous logs for %MACHINE%?',
    },
    {
        name: 'Erase SD Card',
        command: '53',
        icon: machineIcon,
        question: 'Are you sure you want to erase the SD Card for %MACHINE%?',
    },
];

const useStyles = makeStyles({
    window: {
        background: '#f0f3f9',
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: 0,
        flexGrow: 1,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        padding: '25px',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'auto',
        flexGrow: 1,
    },
    contentConfirm: {
        backgroundColor: '#7e8cac',
    },
    button: {
        width: '222px',
        marginBottom: '76px',
    },
    buttonLabel: {
        textAlign: 'center',
    },
    title: {
        textTransform: 'uppercase',
        color: '#ffffff',
        fontSize: '18px',
        textAlign: 'center',
        marginBottom: '76px',
    },
    contentStatus: {
        justifyContent: 'flex-start',
    },
    commandStatusIcon: {
        opacity: 0.5,
        marginTop: '44px',
        width: '80px',
    },
    commandStatusLabel: {
        textTransform: 'uppercase',
        opacity: 0.5,
        marginTop: '17px',
        fontSize: '18px',
        textAlign: 'center',
        color: '#ffffff',
    },
    commandEnabled: {
        opacity: 1.0,
    },
    closeCommandStatus: {
        position: 'absolute',
        right: '10px',
        top: '10px',
        padding: '3px',
    },
    closeCommandStatusImage: {
        filter: hexColorToCSSFilter('#ffffff'),
    },
    separator: {
        width: '100%',
        height: '1px',
        backgroundColor: '#ffffff',
        marginTop: '87px',
    },
    currentMachineStatus: {
        textTransform: 'uppercase',
        marginTop: '15px',
        color: '#ffffff',
        fontSize: '18px',
        textAlign: 'center',
    },
    commandFlow: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    commandFail: {
        backgroundImage: 'linear-gradient(149deg, #97174b, #e73942 114%)',
    },
});

// After how many milliseconds, will we consider this command timed out
const COMMAND_TIME_OUT = 1000 * 60 * 30; // 30 minutes

const gApiClient = Authentication.getAPIClient();

function sendCommand(machineId, command) {
    const params = `machine_id=${machineId}&command_id=${command}`;

    return gApiClient.callApi(
        `admin/executeMachineCommand?${params}`,
        'POST',
        {},
        {}
    );
}

const COMMAND_NOT_SENT = 0;
const COMMAND_SENT = 1;
const COMMAND_RECEIVED = 2;
const COMMAND_DONE = 3;
const COMMAND_FAILED = 4;

/** Single machine - remote actions inner window */
export default function MachineRemoteActions(props) {
    const classes = useStyles();
    const machine = props.machine;
    const [confirmAction, setConfirmAction] = useState();
    const [commandTypes, setCommandTypes] = useState({});
    const [loading, setLoading] = useState(false);
    const [commandId, setCommandId] = useState();
    const [currentCommandType, setCurrentCommandType] = useState();
    const [eventTypes, setEventTypes] = useState({});
    const [latestMachineEvent2, setLatestMachineEvent2] = useState();
    const [commandProgress, setCommandProgress] = useState(COMMAND_NOT_SENT);
    const userDetails = Authentication.getUserDetails();
    const hasPermissions =
        userDetails.is_admin || userDetails.send_machine_commands;
    const [noPermissions, setNoPermissions] = useState(false);

    useEffect(() => {
        gApiClient
            .callApi('admin/getAllMachineCommandTypes', 'GET', {}, {})
            .then((response) => {
                const types = {};
                response.data.forEach((command) => {
                    types[command.command] = command;
                });

                setCommandTypes(types);
            })
            .catch((result) => {
                console.log('error', result);
            });

        // Get mapping between event type and description
        gApiClient
            .callApi('admin/getEventTypes', 'GET', {}, {})
            .then((response) => {
                setEventTypes(response.data);
            });
    }, []);

    const markCommandDone = () => {
        setCommandProgress(COMMAND_DONE);
        setCommandId(null);
    };

    const checkCommandStatus = () => {
        if (!commandId) return;

        gApiClient
            .callApi(
                `admin/getMachineCommandStatus/${commandId}`,
                'GET',
                {},
                {}
            )
            .then((response) => {
                const command = response.data;
                console.log(`Command status for ${commandId}:`, command);
                if (
                    command.timed_out ||
                    DateTime.local().toMillis() - command.date_added >
                        COMMAND_TIME_OUT
                ) {
                    // Command timed out
                    setCommandProgress(COMMAND_FAILED);
                    setCommandId(null);
                    return;
                }

                if (!command || !command.date_retrieved) {
                    // Waiting for machine to receive command
                    return;
                }

                if (command.latest_event2) {
                    // Show latest machine status
                    setLatestMachineEvent2(command.latest_event2);
                }

                if (command.date_complete) {
                    // Command was complete
                    markCommandDone();
                    return;
                }

                if (
                    command.latest_event2 &&
                    command.latest_event2 ===
                        currentCommandType.event2_response_code
                ) {
                    // Reached target event2 for this command
                    markCommandDone();
                } else if (command.date_retrieved) {
                    setCommandProgress(COMMAND_RECEIVED);
                }
            })
            .catch((result) => {
                console.log(result.response);
            });
    };

    useEffect(() => {
        // Periodically check for command status
        if (!commandId) return;

        const timer = setInterval(checkCommandStatus, 1000);

        return () => {
            clearInterval(timer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commandId]);

    if (!machine) {
        // No machine loaded yet
        return (
            <InnerWindow
                className={clsx(classes.window, props.className)}
                title={''}
                loading={true}
            ></InnerWindow>
        );
    }

    const setAction = async (command) => {
        if (!confirmAction) {
            // Ask for confirmation first
            setConfirmAction(command);
            return;
        }

        setCurrentCommandType(commandTypes[command]);

        // User confirmed - send command
        setLoading(true);
        const response = await sendCommand(
            props.machine._id,
            commandTypes[command]._id
        );
        setCommandId(response.data._id);
        setCommandProgress(COMMAND_SENT);
        setLoading(false);
        setConfirmAction(null);
    };

    if (noPermissions) {
        return (
            <InnerWindow
                className={clsx(classes.window, props.className)}
                title={`${machine.alias} / Remote`}
            >
                <div className={classes.root}>
                    <div
                        className={clsx(
                            classes.content,
                            classes.contentConfirm,
                            classes.contentStatus,
                            classes.commandFail
                        )}
                    >
                        <IconButton
                            className={classes.closeCommandStatus}
                            onClick={() => {
                                setNoPermissions(false);
                            }}
                        >
                            <img
                                className={classes.closeCommandStatusImage}
                                src={closeIcon}
                                alt="Close"
                            />
                        </IconButton>

                        <div className={classes.commandFlow}>
                            <img
                                className={clsx(
                                    classes.commandStatusIcon,
                                    classes.commandEnabled
                                )}
                                alt="No permissions"
                                src={commandFailedIcon}
                            />

                            <div
                                className={clsx(
                                    classes.commandStatusLabel,
                                    classes.commandEnabled
                                )}
                            >
                                Sorry, you have no permissions to send commands
                            </div>
                        </div>
                    </div>
                </div>
            </InnerWindow>
        );
    }

    return (
        <InnerWindow
            className={clsx(classes.window, props.className)}
            title={`${machine.alias} / Remote`}
            loading={loading}
        >
            <div className={classes.root}>
                {commandProgress === COMMAND_NOT_SENT ? (
                    <div
                        className={clsx(
                            classes.content,
                            confirmAction ? classes.contentConfirm : null
                        )}
                    >
                        {COMMAND_TYPES.map((commandType) => {
                            if (confirmAction !== commandType.command)
                                return null;

                            return (
                                <div
                                    key={commandType.command}
                                    className={classes.title}
                                >
                                    {commandType.question.replace(
                                        '%MACHINE%',
                                        machine.alias
                                    )}
                                </div>
                            );
                        })}

                        {COMMAND_TYPES.map((commandType) => {
                            if (
                                !confirmAction ||
                                confirmAction === commandType.command
                            ) {
                                return (
                                    <Button
                                        key={commandType.command}
                                        className={classes.button}
                                        label={commandType.name}
                                        rightIcon={commandType.icon}
                                        labelClass={classes.buttonLabel}
                                        onClick={() => {
                                            if (!hasPermissions) {
                                                setNoPermissions(true);
                                                return;
                                            }
                                            setAction(commandType.command);
                                        }}
                                    />
                                );
                            } else {
                                return null;
                            }
                        })}

                        {confirmAction && (
                            <Button
                                className={classes.button}
                                label="Cancel"
                                rightIcon={closeIcon}
                                onClick={() => setConfirmAction(null)}
                            />
                        )}
                    </div>
                ) : (
                    <div
                        className={clsx(
                            classes.content,
                            classes.contentConfirm,
                            classes.contentStatus,
                            commandProgress === COMMAND_FAILED
                                ? classes.commandFail
                                : null
                        )}
                    >
                        <IconButton
                            className={classes.closeCommandStatus}
                            onClick={() => {
                                setCommandProgress(COMMAND_NOT_SENT);

                                // Cancel command status polling
                                setCommandId(null);
                            }}
                        >
                            <img
                                className={classes.closeCommandStatusImage}
                                src={closeIcon}
                                alt="Close"
                            />
                        </IconButton>

                        {commandProgress === COMMAND_FAILED ? (
                            <div className={classes.commandFlow}>
                                <img
                                    className={clsx(
                                        classes.commandStatusIcon,
                                        classes.commandEnabled
                                    )}
                                    alt="Command failed"
                                    src={commandFailedIcon}
                                />
                                <div
                                    className={clsx(
                                        classes.commandStatusLabel,
                                        classes.commandEnabled
                                    )}
                                >
                                    Command Failed - Timed out
                                </div>
                            </div>
                        ) : (
                            <div className={classes.commandFlow}>
                                <img
                                    className={clsx(
                                        classes.commandStatusIcon,
                                        commandProgress >= COMMAND_SENT
                                            ? classes.commandEnabled
                                            : null
                                    )}
                                    alt="Command was sent"
                                    src={commandSentIcon}
                                />
                                <div
                                    className={clsx(
                                        classes.commandStatusLabel,
                                        commandProgress >= COMMAND_SENT
                                            ? classes.commandEnabled
                                            : null
                                    )}
                                >
                                    ({currentCommandType.name}) Command was sent
                                </div>

                                <img
                                    className={clsx(
                                        classes.commandStatusIcon,
                                        commandProgress >= COMMAND_RECEIVED
                                            ? classes.commandEnabled
                                            : null
                                    )}
                                    alt="Machine Received Command"
                                    src={commandReceivedIcon}
                                />
                                <div
                                    className={clsx(
                                        classes.commandStatusLabel,
                                        commandProgress >= COMMAND_RECEIVED
                                            ? classes.commandEnabled
                                            : null
                                    )}
                                >
                                    Machine Received Command
                                </div>

                                <img
                                    className={clsx(
                                        classes.commandStatusIcon,
                                        commandProgress >= COMMAND_DONE
                                            ? classes.commandEnabled
                                            : null
                                    )}
                                    alt="Command Completed"
                                    src={commandCompleteIcon}
                                />
                                <div
                                    className={clsx(
                                        classes.commandStatusLabel,
                                        commandProgress >= COMMAND_DONE
                                            ? classes.commandEnabled
                                            : null
                                    )}
                                >
                                    Command Completed Successfully
                                </div>
                            </div>
                        )}
                        <div className={classes.separator} />

                        <div className={classes.currentMachineStatus}>
                            {latestMachineEvent2 &&
                                eventTypes &&
                                eventTypes[
                                    Radix64.decodeRadix64(latestMachineEvent2)
                                ]}
                        </div>
                    </div>
                )}
            </div>
        </InnerWindow>
    );
}
